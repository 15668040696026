<div class="card card-content">
  <div>
    <h4>Add Task</h4>
  </div>
  <form [formGroup]="Taskform">
    <div class="row">
      <div class="col-md-3">
        <div id="loginform">
          <label class="form-control-label"
            >Select project<span class="text-danger">*</span></label
          >
          <mat-select
            class="form-control email-input select-placeholder"
            formControlName="project"
            placeholder="Select project"
          >
            <mat-option
              *ngFor="let project of projectList"
              [value]="project.projectId"
              (click)="projectData(project)"
            >
              {{ project.projectName }}
            </mat-option>
          </mat-select>
          <div *ngIf="submitted && project.invalid">
            <small *ngIf="project.errors?.required" class="text-danger"
              >Please select project</small
            >
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div id="loginform">
          <label class="form-control-label"
            >Select Task Group<span class="text-danger">*</span></label
          >
          <mat-select
            class="form-control email-input select-placeholder"
            formControlName="taskgroup"
            placeholder="Select task group"
          >
            <mat-option
              *ngFor="let taskgroup of taskgrouplist"
              [value]="taskgroup.taskGroupId"
              >{{ taskgroup.taskGroupName }}
            </mat-option>
          </mat-select>
          <div *ngIf="submitted && taskgroup.invalid">
            <small *ngIf="taskgroup.errors?.required" class="text-danger"
              >Please select task group</small
            >
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <button
          mat-raised-button
          type="submit"
          (click)="addRow()"
          class="btn create-button btn-res mr-3"
        >
          Add Row
        </button>

        <button
          mat-raised-button
          type="submit"
          (click)="importEmployee()"
          class="btn create-button btn-res"
        >
          Import
        </button>
      </div>
    </div>
    <div
      class="table-responsive"
      style="max-height: 363px; overflow: auto; overflow-x: hidden"
    >
      <table class="table table-border table-responsive">
        <thead class="text-nowrap">
          <th>
            Task Name<span class="text-danger">*</span
            ><span class="resizer"></span>
          </th>
          <th>
            Task Description<span class="text-danger">*</span
            ><span class="resizer"></span>
          </th>
          <th>
            Start Date<span class="text-danger">*</span
            ><span class="resizer1"></span>
          </th>
          <th>
            End Date<span class="text-danger">*</span
            ><span class="resizer1"></span>
          </th>
          <!-- <th>Hours<span class="text-danger">*</span><span class="resizer1"></span></th>
                    <th>Minutes<span class="text-danger">*</span><span class="resizer1"></span></th> -->
          <th>Action</th>
        </thead>
        <tbody>
          <ng-container
            formArrayName="tableRows"
            *ngFor="let group of getFormControls.controls; let i = index"
          >
            <tr *ngIf="group.get('isEditable').value" [formGroupName]="i">
              <td>
                <div>
                  <input
                    type="text"
                    formControlName="taskname"
                    placeholder="Enter task"
                    maxlength="150"
                    (keyup)="validateTaskname($event, i)"
                    class="form-control email-input select-placeholder"
                  />
                  <div
                    *ngIf="
                      (submitted && group['controls'].taskname.invalid) ||
                      group['controls'].taskname.dirty
                    "
                  >
                    <small
                      *ngIf="group['controls'].taskname.errors?.required"
                      class="text-danger"
                      >Please enter task name</small
                    >
                    <!-- <small *ngIf="tasknameexist" class="text-danger"> Task already exist</small> -->
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <textarea
                    type="text"
                    rows="1"
                    formControlName="taskdescription"
                    placeholder="Enter description"
                    maxlength="250"
                    class="form-control email-input"
                  >
                  </textarea>
                  <div
                    *ngIf="
                      (submitted &&
                        group['controls'].taskdescription.invalid) ||
                      group['controls'].taskdescription.dirty
                    "
                  >
                    <small
                      *ngIf="group['controls'].taskdescription.errors?.required"
                      class="text-danger"
                      >Please enter task description</small
                    >
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <input
                    formControlName="startdate"
                    placeholder="Enter start date"
                    (change)="mintaskendDate($event, i)"
                    type="date"
                    [attr.id]="'dtPicker' + i"
                    [min]="min"
                    [disabled]="startDate_disable"
                    [readonly]="startDate_disable"
                    class="form-control email-input select-placeholder dateclass placeholderclass"
                  />

                  <div
                    *ngIf="
                      (submitted && group['controls'].startdate.invalid) ||
                      group['controls'].startdate.dirty
                    "
                  >
                    <small
                      *ngIf="group['controls'].startdate.errors?.required"
                      class="text-danger"
                      >Please select StartDate</small
                    >
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <input
                    formControlName="enddate"
                    placeholder="Enter end date"
                    [readonly]="endDate_disable"
                    [disabled]="endDate_disable"
                    [attr.id]="'enddtPicker' + i"
                    type="date"
                    class="form-control email-input select-placeholder dateclass placeholderclass"
                  />
                </div>
                <div
                  *ngIf="
                    (submitted && group['controls'].enddate.invalid) ||
                    group['controls'].enddate.dirty
                  "
                >
                  <small
                    *ngIf="group['controls'].enddate.errors?.required"
                    class="text-danger"
                    >Please select end date</small
                  >
                </div>
              </td>
              <!-- <td>
                            <div>
                                <input type="text" 
                                    formControlName="hours"  
                                    placeholder="Enter hours"
                                    (keypress)="noNumber($event)"
                                    maxlength="3"
                                    class="form-control email-input select-placeholder" >
                                    <div *ngIf="(submitted && group['controls'].hours.invalid) || group['controls'].hours.dirty">
                                        <small *ngIf="group['controls'].hours.errors?.required" class="text-danger">Please enter hours</small>  
                                    </div> 
                            </div>
                        </td>
                        <td>
                            <div>
                                <input type="text" 
                                    formControlName="minutes"
                                    placeholder="Enter minutes"
                                    maxlength="2"
                                    (keypress)="noNumber($event)"
                                    class="form-control email-input select-placeholder" >
                                    <div *ngIf="(submitted && group['controls'].minutes.invalid) || group['controls'].minutes.dirty">
                                        <small *ngIf="group['controls'].minutes.errors?.required" class="text-danger">Please enter minutes</small>  
                                    </div> 
                            </div>
                        </td> -->
              <td style="text-align: center">
                <!-- <mat-icon class="done" (click)="doneRow(group)">done</mat-icon> -->
                <mat-icon class="delete" (click)="deleteRow(i)"
                  >delete</mat-icon
                >
              </td>
            </tr>
            <tr *ngIf="!group.get('isEditable').value">
              <td>
                {{ group.get("taskname").value }}
              </td>
              <td>
                {{ group.get("taskdescription").value }}
              </td>
              <td>
                {{ group.get("startdate").value }}
              </td>
              <td>
                {{ group.get("enddate").value }}
              </td>
              <td>
                {{ group.get("hours").value }}
              </td>
              <td>
                {{ group.get("minutes").value }}
              </td>
              <td>
                <mat-icon class="edit" (click)="editRow(group)">edit</mat-icon>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="action-container">
      <button
        mat-raised-button
        class="btn create-button"
        style="float: right; color: white; margin-top: 15px"
        type="submit"
        (click)="submitForm()"
      >
        Submit
      </button>
    </div>
  </form>
</div>

<div
  *ngIf="backdrop"
  class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"
  style="height: 527vh !important"
></div>


<div class="text-center" *ngIf="success">
  <div class="alert alert-success toast-msg" role="alert">
    <strong>Success!</strong><br />{{ sucess_msg }}
  </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible toast-msg">
  <strong>Error!</strong><br />{{ error_msg }}
  <button
    *ngIf="importerror"
    (click)="showerrormessage()"
    class="btn btn-danger"
  >
    View Error Records
  </button>
</div>
<div
  *ngIf="projectLoader"
  class="d-flex align-items-center justify-content-center"
  style="position: fixed; top: 0; left: 0; width: 100%; color: #03c9d7; height: 100vh; background: rgba(255, 255, 255, 0.5); z-index: 9999;"
>
  <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
</div>

<div
  *ngIf="taskLoader"
  class="d-flex flex-column align-items-center justify-content-center"
  style="position: fixed; top: 0; left: 0; width: 100%; color: #03c9d7; height: 100vh; background: rgba(255, 255, 255, 0.5); z-index: 9999;"
>
  <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></div>
  <p style="margin-top: 1rem; font-size: 1.2rem; font-weight: 500;">Tasks are loading...</p>
</div>