import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { GlobalserviceService } from '../providers/globalservice.service';
import { MatDialog } from '@angular/material';
import { responseMsgTimeOut } from '../../app/providers/properties';
import { ShowErrorEmployeeComponent } from '../show-error-employee/show-error-employee.component';
import { AssignEmployeeComponent } from '../my-components/approver-list/assign-employee/assign-employee.component';
import { AssignEmployeeImportComponent } from '../assign-employee-import/assign-employee-import.component';
@Component({
  selector: 'app-assign-employee-task',
  templateUrl: './assign-employee-task.component.html',
  styleUrls: ['./assign-employee-task.component.css']
})
export class AssignEmployeeTaskComponent implements OnInit {
  assignformbyemployee: FormGroup;
  assignformbytask: FormGroup;
  control: FormArray;
  mode: boolean;
  touchedRows: any;
  touchedRows1: any;
  projectList = [];
  taskList: any;
  min: Date;
  max: Date;
  startDate_disable: boolean = true;
  endDate_disable: boolean = true;
  minStart: Date;
  projectId: any;
  taskId: any;
  employeeProjects: any = [];
  projectlist = [];
  selectedvalue: string;
  employee_by_task: boolean = false;
  task_by_employee: boolean = false;
  submitted2: boolean = false;
  submitted1: boolean = false;
  today: any = new Date();
  employeelastdate: any;
  EmpId: any;
  error: any = false;
  errormessagejson: any;
  importerror: boolean;
  success = false;
  sucess_msg: any;
  backdrop: any = false;
  loader: any = false;
  error_msg: any;
  projectLoader: boolean = true;
  taskLoader: boolean = false;
  constructor(
    private gs: GlobalserviceService,
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private dialog: MatDialog
  ) { }



  ngOnInit(): void {
    const Data = JSON.parse(localStorage.getItem('UserData'))
    this.EmpId = Data.employeeId;
    // this.gs.getProjectByOrganization(Data.organization.organizationId).subscribe((projectslist:any ) => {
    //       this.projectList = projectslist;
    // })

    this.gs.getProjectByOrganization(Data.organization.organizationId).subscribe((pa: any) => {
      this.projectList = pa;
      this.projectLoader = false;
    });

    this.touchedRows = [];
    this.assignformbyemployee = this.fb.group({
      tableRows: this.fb.array([]),
      projectname: ['', Validators.required],
      taskname: ['', Validators.required]
    });
    this.addRow();

    this.touchedRows1 = [];
    this.assignformbytask = this.fb.group({
      tableRows1: this.fb.array([]),
      projectname1: ['', Validators.required],
      employee1: ['', Validators.required]
    });
    this.addRow1();


  }
  ngAfterOnInit() {
    this.control = this.assignformbyemployee.get('tableRows') as FormArray;
    // this.control2 = this.assignformbytask.get('tableRows2') as FormArray;
  }

  initiateForm(): FormGroup {
    return this.fb.group({
      employee: ['', Validators.required],
      startdate: ['', [Validators.required]],
      enddate: ['', [Validators.required]],
      hours: ['', [Validators.required]],
      minutes: ['', [Validators.required]],
      isEditable: [true]
    });
  }
  initiateForm1(): FormGroup {
    return this.fb.group({
      taskname1: ['', Validators.required],
      startdate1: ['', [Validators.required]],
      enddate1: ['', [Validators.required]],
      hours1: ['', [Validators.required]],
      minutes1: ['', [Validators.required]],
      isEditable: [true]
    });
  }

  // date validation
  mintaskendDatenew(date: any, index: any) {
    this.minStart = new Date(date.value)
    console.log(date.target.value);
    document.getElementById("TenddtPicker" + index).setAttribute("min", date.target.value);
    this.endDate_disable = false
  }
  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // date validation
  mintaskendDate(date: any, index: any) {

    console.log("mindate");
    console.log(date.target.value);
    document.getElementById("enddtPicker" + index).setAttribute("min", date.target.value);

    this.endDate_disable = false
  }

  taskData(task) {
    this.min = task.taskStartDate;
    console.log(this.min)
    this.max = task.taskEndDate;
    console.log(this.max)
    this.startDate_disable = false;
    if (this.task_by_employee) {

      let control = this.assignformbyemployee.get('tableRows') as FormArray;
      control.controls.forEach(element => {

        element.patchValue({
          startdate: "",
          enddate: "",
        })
      });
    }
  }


  taskDataBytask(task, control, index) {
    this.min = new Date(task.taskStartDate)
    console.log(this.min)
    this.max = new Date(task.taskEndDate)
    console.log(this.max)
    this.startDate_disable = false;

    console.log(control);

    document.getElementById("dtPicker" + index).setAttribute("min", task.taskStartDate);
    document.getElementById("dtPicker" + index).setAttribute("max", task.taskEndDate);
    document.getElementById("enddtPicker" + index).setAttribute("max", task.taskEndDate);
    // control.startdate1.setValue("");
    // control.enddate1.setValue("");
  }



  projectData(project) {
    // this.employeeProjects = project.employeeProjects;
    // console.log(this.employeeProjects);
    this.taskList = [];
    console.log(project.employeeProjects);
    this.today = this.datepipe.transform(this.today, 'YYYY-MM-dd');
    this.employeeProjects.length = 0;
    for (let a of project.employeeProjects) {
      console.log(a.employee);
      console.log(a.employee.employeeLastWorkingDate);

      if (a.employee.employeeLastWorkingDate != undefined) {
        console.log("IF 1");

        this.employeelastdate = a.employee.employeeLastWorkingDate
        console.log(this.employeelastdate);

        if (this.employeelastdate >= this.today) {
          console.log("IF 2");
          this.employeeProjects.push(a);
          console.log(this.employeeProjects);
        }
      }
      else {
        this.employeeProjects.push(a);
        console.log(this.employeeProjects);
      }
    }
    console.log(this.employeeProjects);

    this.taskLoader = true;
    this.gs.getTaskById(project.projectId).subscribe(
      (x: any) => {
        this.taskList = [];
        this.taskList = x;
        this.taskLoader = false;

        console.log(this.taskList);

      },(err=>{

        if (err.error.errorCode === 204) {
          this.taskLoader = false;
          setTimeout(()=>{

            alert('No Tasks are Available!');
          },500)
        }
        console.log("Error Catches", err);
        
      })
    )
    if (this.task_by_employee) {
      let control = this.assignformbyemployee.get('tableRows') as FormArray;
      control.controls.forEach(element => {
        element.patchValue({
          startdate: "",
          enddate: "",
        })
      });
    } else {
      let control = this.assignformbytask.get('tableRows1') as FormArray;
      control.controls.forEach(element => {
        element.patchValue({
          startdate1: "",
          enddate1: "",
        })
      });
    }

  }

  selected(event: any) {
    this.selectedvalue = event;
    if (this.selectedvalue == 'employee') {
      this.task_by_employee = true;
      this.employee_by_task = false;
    }
    else if (this.selectedvalue == 'task') {
      this.task_by_employee = false;
      this.employee_by_task = true;
    }
  }
  addRow() {
    const control = this.assignformbyemployee.get('tableRows') as FormArray;
    control.push(this.initiateForm());
  }
  addRow1() {
    const control = this.assignformbytask.get('tableRows1') as FormArray;
    control.push(this.initiateForm1());
  }

  deleteRow(index1: number) {
    const control = this.assignformbyemployee.get('tableRows') as FormArray;
    control.removeAt(index1);
  }
  deleteRow1(index2: number) {
    const control = this.assignformbytask.get('tableRows1') as FormArray;
    control.removeAt(index2);
  }

  editRow(group: FormGroup) {
    group.get('isEditable').setValue(true);
  }
  editRow1(group: FormGroup) {
    group.get('isEditable').setValue(true);
  }

  doneRow(group: FormGroup) {
    group.get('isEditable').setValue(false);
  }
  doneRow1(group: FormGroup) {
    group.get('isEditable').setValue(false);
  }

  saveUserDetails() {
    console.log(this.assignformbyemployee.value);
  }
  saveUserDetails1() {
    console.log(this.assignformbytask.value);
  }

  get getFormControls() {
    const control = this.assignformbyemployee.get('tableRows') as FormArray;
    return control;
  }
  get getFormControls1() {
    const control = this.assignformbytask.get('tableRows1') as FormArray;
    return control;
  }
  submitForm() {
    this.submitted1 = true;
    const control = this.assignformbyemployee.get('tableRows') as FormArray;
    if (this.assignformbyemployee.invalid) {
      return
    }

    // const fromcontrol = this.assignformbyemployee.get('tableRows') as FormArray;

    var valueArr = control.controls.map(function (item) { return item.value.employee });
    var isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx
    });

    console.log(isDuplicate);

    //    console.log("this.tasknameexist" + this.tasknameexist)

    if (this.assignformbyemployee.valid && isDuplicate) {
      Swal.fire({
        icon: 'error',
        text: "Employee  Repeats in Form"

      })
      return
    }


    this.touchedRows = control.controls.filter(row => row.touched).map(row => row.value);
    console.log(this.touchedRows);

    let assignformbyemployeearray = [];
    this.touchedRows.forEach(element => {

      let startdate = this.datepipe.transform(element.startdate, 'YYYY-MM-dd');
      let enddate = this.datepipe.transform(element.enddate, 'YYYY-MM-dd');

      let assignedhours = Number(element.hours);
      let assignedMinutes = Number(element.minutes);

      if (assignedMinutes >= 60) {
        assignedhours += Math.floor(assignedMinutes / 60);
        assignedMinutes = assignedMinutes % 60;
      }


      var employee_by_taskobj = {
        task: {
          taskId: this.assignformbyemployee.value.taskname,
          project: {
            projectId: this.assignformbyemployee.value.projectname
          }
        },
        employee: {
          employeeId: element.employee
        },
        assignedhours: assignedhours,
        assignedminutes: assignedMinutes,
        startdate: startdate,
        enddate: enddate
      }
      assignformbyemployeearray.push(employee_by_taskobj);
    });

    console.log(assignformbyemployeearray);


    this.gs.assignEmployee(assignformbyemployeearray).subscribe((a: any) => {
      Swal.fire({
        icon: 'success',
        text: a.message
      })
      window.location.reload()
    }, err => {
      //alert("error")
      // console.log(err.error);                                                  
      assignformbyemployeearray.length = 0;
      //  Swal.fire({
      //    icon: 'error',
      //    text: err.error.message

      //  })
      // setTimeout(() => {
      //   window.location.reload();
      // }, 3000);
    })

  }
  submitForm1() {
    this.submitted2 = true;
    const control = this.assignformbytask.get('tableRows1') as FormArray;
    if (this.assignformbytask.invalid) {

      const invalid = [];
      const controls = this.assignformbytask.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          console.log(name);
          invalid.push(name);
        }
      }

      return
    }

    var valueArr = control.controls.map(function (item) { return item.value.taskname1 });
    var isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx
    });

    console.log(isDuplicate);

    //    console.log("this.tasknameexist" + this.tasknameexist)

    if (this.assignformbytask.valid && isDuplicate) {
      Swal.fire({
        icon: 'error',
        text: "Task Repeats in Form"

      })
      return
    }

    this.touchedRows1 = control.controls.filter(row => row.touched).map(row => row.value);
    console.log(this.touchedRows1);
    let assignformbytaskarray = [];
    this.touchedRows1.forEach(element => {

      let startdate = this.datepipe.transform(element.startdate1, 'YYYY-MM-dd');
      let enddate = this.datepipe.transform(element.enddate1, 'YYYY-MM-dd');


      let assignedhours = Number(element.hours1);
      let assignedMinutes = Number(element.minutes1);

      if (assignedMinutes >= 60) {
        assignedhours += Math.floor(assignedMinutes / 60);
        assignedMinutes = assignedMinutes % 60;
      }


      var employee_by_taskobj = {
        task: {
          taskId: element.taskname1,
          project: {
            projectId: this.assignformbytask.value.projectname1
          }
        },
        employee: {
          employeeId: this.assignformbytask.value.employee1
        },
        assignedhours: assignedhours,
        assignedminutes: assignedMinutes,
        startdate: startdate,
        enddate: enddate
      }
      assignformbytaskarray.push(employee_by_taskobj);
    });

    console.log(assignformbytaskarray);

    this.gs.assignEmployee(assignformbytaskarray).subscribe((a: any) => {
      Swal.fire({
        icon: 'success',
        text: a.message
      })
      window.location.reload()
    }, err => {
      //alert("error")
      // console.log(err.error);
      assignformbytaskarray.length = 0;
      //  Swal.fire({
      //    icon: 'error',
      //    text: err.error.message

      //  })
      //  setTimeout(() => {
      //   window.location.reload();
      //  }, 3000);
    })
  }



  /* validation ++++++++++++++++ */
  get projectname() {
    return this.assignformbyemployee.get('projectname');
  }
  get taskname() {
    return this.assignformbyemployee.get('taskname');
  }
  get projectname1() {
    return this.assignformbytask.get('projectname1');
  }
  get employee1() {
    return this.assignformbytask.get('employee1');
  }



  importEmployee() //Add Dialog Box
  {
    const dialogRef = this.dialog.open(AssignEmployeeImportComponent, {
      //height: 'auto',width: '600px',
      width: '500px',
      // height: 'fit-content',
      // maxWidth: '90%', 
    });

    dialogRef.afterClosed().subscribe(result => {
      //this.departmentList();
      if (result.statusCode == 200) {
        this.success = true;
        this.sucess_msg = result.description;
        setTimeout(() => { this.success = false; }, responseMsgTimeOut)
      }
      else {
        if (result.length != 0) {

          this.error = true;
          // this.error_msg = result.message;
          this.importerror = true;
          this.errormessagejson = result;
          // this.showerrormessage();
          // setTimeout(() => {this.error = false}, responseMsgTimeOut)
        }
        else {
          if (result.statusCode > 400) {
            this.error = true;

            this.importerror = false;
          }
        }

      }
    });
  }


  showerrormessage() {
    const dialogRef = this.dialog.open(ShowErrorEmployeeComponent, {
      //height: 'auto',width: '600px',
      width: '600px', height: 'fit-content', data: this.errormessagejson
    });
    this.error = false;
  }
}
